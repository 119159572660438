@import 
  "variables",
  "mixins",
  "normalize",
  "typography",
  "highlights";

*, *:before, *:after { 
  @include box-sizing(border-box);
}

body {
  background: $color-green url("images/bg.png");
  color: #fff;
  font-family: $font-family-paragraph;
}

a {
  color: #fff;
  text-decoration: none;
  border-bottom: 1px solid #fff;

  &:hover {
    border-bottom: none;
  }
}

// Layout
.main-header {
  padding: 1rem 0;
  
  .menu {
    text-align: right;
    
    @include breakpoint(xs) {
      text-align: left;
    }
  }
  
  .button {
    margin-top: 2rem;
  }

  .is-single & {
    .container {
      padding-left: 0;
    }

    h1 {
      padding-top: 0;
    }
  }

  .is-home & .container {
    max-width: 1280px;
  }
  
  .grid {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 50rem);
    align-items: center;
    
    @include breakpoint(xs) {
      grid-template-columns: minmax(0, 1fr);
    }
  }

  img {
    border-radius: 50%;
  }

  h1 {
    padding-top: 0.5rem;
    text-shadow: 0 1px 0 black(0.3);
    color: #fff;
    @include all-caps-grotesque(1.5rem);

    @include breakpoint(xs) {
      padding-top: 0;
    }

    a {
      border-bottom: none;
    }
  }
}

.menu {
  ul {
	margin: 0;
	padding: 0;
	list-style: none;
  }

  li {
	display: inline-block;

	&.active {
	  font-weight: bold;
	}
  }

  li + li {
	margin-left: 20px;
  
    @include breakpoint(xs) {
      margin-left: 10px;
    }
  }

  li a {
	border-bottom: none;

	  &:hover {
	    border-bottom: 1px solid #fff;
	  }
  }

  @include breakpoint(xs) {
	 margin-top: 0.5rem;
  }
}

.intro {
  max-width: 780px;
  margin: 5rem auto;  
  display: grid;
  gap: 3rem;
  grid-template-columns: 200px minmax(0, 1fr);
  
  @include breakpoint(xs) {
    grid-template-columns: minmax(0, 1fr);
    gap: 2rem;
    margin: 3rem auto;
  }
  
  p {
    font-size: 1.2rem; 
    letter-spacing: -0.5px;
  }
}

.main-footer {
  padding: 2.5rem 0;
  font-family: $font-family-heading;
  font-size: 0.7rem;
  font-weight: 900;
  text-transform: uppercase;
  text-align: center;
  color: black(0.3);

  @include breakpoint(xs) {
    padding: 1.25rem 0;
  }

  a {
    color: white(0.6);
    border-bottom: none;

    &:hover {
      color: white(0.8);
    }
  }
}

.container {
  width: 90%;
  max-width: 780px;
  margin: 0 auto;
}

.white {
  background-color: #fff;
  color: #484c50;

  .is-single & {
    padding: 2rem 0;
  }
}

.featured {
  background-color: $color-blue;

  .container {
    padding: 2rem 0;
    // background: url("images/book.png") no-repeat 100% 50px;
  }
}

.timeline  {
  padding: 5rem 0 5rem 6rem;
  position: relative;
  
  &:before {
    content: "";
    display: block;
    width: 4px;
    position: absolute;
    top: 0;
    left: 45px;
    bottom: 0;
    background-color: #b8e9d8;

    @include breakpoint(xs) {
      display: none;
    }
  }

  @include breakpoint(xs) {
    padding: 2.5rem 0;
  }
}

/*
  Post module, either a simple title + meta or full post
*/
.post {
  position: relative;

  &:before {
    content: "";
    display: block;
    width: 14px;
    height: 14px;
    background-color: $color-orange;
    box-shadow: 0 0 0 5px #fff;
    border-radius: 30px;
    position: absolute;
    left: -80px;
    top: 14px;
  }

  .is-single & {
    &:before {
      display: none;
    }
  }

  & + .post {
    margin-top: 2rem;
  }

  .post-title {
    line-height: 1.1;
    letter-spacing: -1px;
    margin-bottom: 0.5rem;

    .is-single & {
      border-bottom: 2px solid #D8DFE1;
      padding-bottom: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }

  .post-meta {
    font-family: $font-family-heading;
    font-size: 0.7rem;
    font-weight: 900;
    text-transform: uppercase;
    color: #b6c0ca;

    a, a:hover {
      color: #b6c0ca;
      border-bottom: none;
    }
  }

  a {
    color: #2b3e50;
    border-bottom: none;

    &:hover {
      border-bottom: 1px solid #2b3e50;
    }
  }
}

/*
  Main post content 
  All post typographical styles go here
*/
.post-entry {

  h4 {
    @include all-caps-grotesque(1.2rem);
    line-height: 1.2;
    color: $color-blue;
    margin: 3rem 0 1.5rem;
  }

  h6 {
    @include all-caps-grotesque(0.7rem);
    color: #9aa3ab;
    margin-top: 2rem;
  }

  p, li {
    line-height: 1.6;
  }

  ul, ol {
    margin: 1rem 0 1rem 1rem;
    padding: 0;
  }

  li + li {
    margin-top: 0.5rem;
  }

  a {
    color: $color-orange;
    text-decoration: none;
    border-bottom: 1px solid $color-orange;

    &:hover {
      border-bottom: none;
    }
  }

  small,
  .small {
    font-size: 80%;
    color: #9aa3ab;
  }

  img.left {
    margin-right: 1rem;
  }

  img.full {
    width: 100%;
  }

  .img-caption {
    text-align: center;
    font-style: italic;
    font-size: 85%;
    color: lighten(#484c50, 20%);
  }
}

.posts {
  margin: 0 !important;
  padding: 0 !important;
  list-style: none;

  li + li {
	margin-top: 30px;
  }

  li a { 
	color: #2b3e50;
	border-bottom: none;

	&:hover {
	  color: $color-orange;
	  border-bottom: 1px solid $color-orange;
	}
  }

  h2 {
	  line-height: 1.2;
	  margin-bottom: 0.5rem;
  }
}

/*
  Disqus container
*/
.post-comments {
  margin-top: 3rem;
}

// high contrast CTA-like prompt
.prompt {
  margin-top: 1rem;
  background: #e74c3c;
  color: #fff;
  padding: 1rem;
  border-radius: 3px;

  > a {
    color: #fff;
    border-bottom-color: #fff;
  }
}

// Convertkit subscription form
.convertkit-form {
  margin-top: 2rem;
  background-color: rgba(216, 223, 225, 0.25);
  padding: 1rem;
  border-radius: 3px;

  h5 {
    margin-bottom: 1rem;
  }
}

/*
  Next/previous article navigation below each post
*/
.post-nav {
  margin-top: 2rem;
  padding-top: 2rem;
  margin-bottom: 3rem;
  border-top: 2px solid #D8DFE1;
  line-height: 1.2;
  @extend %clearfix;

  a {
    color: #2b3e50;
    border-bottom: none;
    @include bold-text(1.2rem);

    &:hover {
      border-bottom: 1px solid #2b3e50;
    }
  }
}

.post-nav__previous {
  width: 48%;
  float: left;
  text-align: right;

  @include breakpoint(xs) {
    float: none;
    width: 100%;
    text-align: left;
  }
}

.post-nav__next {
  width: 48%;
  float: right;

  @include breakpoint(xs) {
    float: none;
    width: 100%;
    margin-top: 1.5rem;
  }
}

// Embed container (for Insta, YouTube etc)
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 1rem; 
  height: 0; 
  overflow: hidden;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.embed-container + .embed-container {
  margin-top: 3rem;
}

.twitter-tweet[style] {
  margin: 1.5rem auto !important;
}

// Talks
.talks {
  display: grid;
  gap: 3rem;
  margin: 0 !important;
  padding: 0;
  list-style: none;

  > li + li {
    margin-top: 0;
  }

  small {
    display: block;
    text-align: center;
  }

  iframe {
    max-width: 100%;
  }
}

// Breaking
.breaking {
  text-align: center;
  background: #e74c3c;
  color: #fff;
  padding: 0.5rem;
  font-size: 0.8rem;

  > a {
    color: #fff;
    border-bottom-color: #fff;
  }
}

// Products
.products {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  gap: 2rem;
  margin: 1.5rem 0;
  
  @include breakpoint(xs) {
    grid-template-columns: minmax(0, 1fr);
  }
  
  h2 {
    text-align: center;
    border: 3px solid $color-blue;
    margin: 0;
    padding: 1rem;
    background-color: $color-blue;
    color: #fff;
    border-radius: 6px 6px 0 0;
  }
}

.product__section {
  padding: 1.5rem 2rem;
  border: 3px solid $color-greyBlue;
  border-top: none;
  
  p {
    margin-top: 0;
  }
  
  ul {
    margin-bottom: 0;
  }
}

// Forms
a.button {
  display: block;
  background-color: $color-orange;
  color: #fff;
  border-radius: 4px;
  font-weight: 700;
  text-align: center;
  padding: 12px 24px;
  border: none;
}

a.button:hover {
  background-color: darken($color-orange, 10%);
}

#mlb2-9373587.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody, #mlb2-9373587.ml-form-embedContainer .ml-form-embedWrapper .ml-form-successBody,  {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

#mlb2-9374167.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody, #mlb2-9374167.ml-form-embedContainer .ml-form-embedWrapper .ml-form-successBody {
  padding: 0 !important;
}

#mlb2-9374167.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-formContent.horozintalForm {
  padding-bottom: 0 !important;
}

// Utilities
.left   { float: left; }
.right  { float: right; }
.text-center  { text-align: center; }
.margin-top   { margin-top: 1rem; }
.margin-bottom { margin-bottom: 1rem; }
.margin-right { margin-right: 1rem; }
