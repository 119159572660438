/*-----------------------------*\
 * VARIABLES
\*-----------------------------*/

// Colour palette
$color-blue: #2b3e50;
$color-green: #16b980;
$color-orange: #e74c3c;
$color-greyBlue: #D8DFE1;
$color-grey: #ecf0f1;


$font-family-heading: "Josefin Sans", sans-serif;
$font-family-paragraph: "Open Sans", sans-serif;

