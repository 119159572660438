/*-----------------------------*\
 * TYPOGRAPHY
\*-----------------------------*/

@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

html {
  font-size: 20px;  
  -webkit-font-smoothing: antialiased;

  @include breakpoint(xs) {
    font-size: 16px;
  }
}

body {
  font-size: 1rem;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

h1 {
  @include bold-text(2.1rem);
  color: $color-blue;
  line-height: 1.2;
}

h2 {
  @include bold-text(1.5rem);
}

h3 {
  @include all-caps-grotesque(1.2rem);
  color: $color-orange;
  margin-bottom: 1.5rem;
}

h4 {
  font-family: $font-family-paragraph;
  font-size: 1rem;
  font-weight: 300;
  color: white(0.6);

  a {
    color: white(0.6);    
  }
}

h5 {
  @include all-caps-grotesque(0.7rem);
  color: #9aa3ab;
}

p {
  font-family: $font-family-paragraph;
  font-weight: 400;
  margin-bottom: 0;

  & + p {
    margin-top: 1rem;
  }
}

blockquote {
  margin-left: 0;
  padding-left: 1rem;
  border-left: 4px solid #b8e9d8;

  p {
    font-style: italic;
    font-size: 1.3rem;
  }
}

hr {
  border: 0;
  height: 2px;
  margin: 2rem 0;
  background-image: linear-gradient(to right, rgba(216, 223, 225, 0), rgba(216, 223, 225, 1), rgba(216, 223, 225, 0));
}
