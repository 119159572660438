
/***********************/
/* SYNTAX HIGHLIGHTING */
/***********************/

.highlight { 
  background-color: #2B303B;
  padding: 15px;
  margin: 20px 0;
}

.highlight pre {
  /* overflow: scroll; Prefer no word wrap? Uncomment this line and comment out the 2 lines below. */
  word-break: break-all;
  word-wrap: break-word;
  margin: 0;
}
 
code {
  font-family: Menlo, Monaco, Consolas, Inconsolata, "Andale Mono", monospace;
  font-size: 0.8rem;
  line-height: 1.4;
  background-color: #FFFDE2;
  color: #C78B38;
  padding: 2px 4px;

  .highlight & {
    background-color: transparent;
    color: #c0c5cb;
    padding: 0;
  } 
}
 
/* Comment */
.highlight .c, .highlight .c1 { color: #586E75 }
/* Error */
.highlight .err { color: #bf616a }
/* Generic */
.highlight .g { color: #c0c5cb }
/* Keyword */
.highlight .k { color: #c0c5cb }
/* Literal */
.highlight .l { color: #c0c5cb }
/* Name */
.highlight .n { color: #c0c5cb }
/* Operator */
.highlight .o { color: #c0c5cb }
/* Other */
.highlight .x { color: #CB4B16 }
/* Punctuation */
.highlight .p { color: #c0c5cb }
/* Comment.Multiline */
.highlight .cm { color: #586E75 }
/* Comment.Preproc */
.highlight .cp { color: #c0c5cb }
/* Comment.Single */
.highlight .c1 { color: #586E75 }
/* Comment.Special */
.highlight .cs { color: #c0c5cb }
/* Generic.Deleted */
.highlight .gd { color: #a3be8c }
/* Generic.Emph */
.highlight .ge { color: #c0c5cb; font-style: italic }
/* Generic.Error */
.highlight .gr { color: #DC322F }
/* Generic.Heading */
.highlight .gh { color: #CB4B16 }
/* Generic.Inserted */
.highlight .gi { color: #c0c5cb }
/* Generic.Output */
.highlight .go { color: #c0c5cb }
/* Generic.Prompt */
.highlight .gp { color: #c0c5cb }
/* Generic.Strong */
.highlight .gs { color: #c0c5cb; font-weight: bold }
/* Generic.Subheading */
.highlight .gu { color: #CB4B16 }
/* Generic.Traceback */
.highlight .gt { color: #c0c5cb }
/* Keyword.Constant */
.highlight .kc { color: #CB4B16 }
/* Keyword.Declaration */
.highlight .kd { color: #bf616a }
/* Keyword.Namespace */
.highlight .kn { color: #c0c5cb }
/* Keyword.Pseudo */
.highlight .kp { color: #c0c5cb }
/* Keyword.Reserved */
.highlight .kr { color: #bf616a }
/* Keyword.Type */
.highlight .kt { color: #a3be8c }
/* Literal.Date */
.highlight .ld { color: #c0c5cb }
/* Literal.Number */
.highlight .m { color: #a3be8c }
/* Literal.String */
.highlight .s { color: #a3be8c }
/* Name.Attribute */
.highlight .na { color: #c0c5cb }
/* Name.Builtin */
.highlight .nb { color: #B58900 }
/* Name.Class */
.highlight .nc { color: #d08770 }
/* Name.Constant */
.highlight .no { color: #c0c5cb }
/* Name.Decorator */
.highlight .nd { color: #bf616a }
/* Name.Entity */
.highlight .ni { color: #CB4B16 }
/* Name.Exception */
.highlight .ne { color: #CB4B16 }
/* Name.Function */
.highlight .nf { color: #bf616a }
/* Name.Label */
.highlight .nl { color: #c0c5cb }
/* Name.Namespace */
.highlight .nn { color: #c0c5cb }
/* Name.Other */
.highlight .nx { color: #555 }
/* Name.Property */
.highlight .py { color: #c0c5cb }
/* Name.Tag */
.highlight .nt { color: #bf616a }
/* Name.Variable */
.highlight .nv { color: #bf616a }
/* Operator.Word */
.highlight .ow { color: #c0c5cb }
/* Text.Whitespace */
.highlight .w { color: #c0c5cb }
/* Literal.Number.Float */
.highlight .mf { color: #a3be8c }
/* Literal.Number.Hex */
.highlight .mh { color: #d08770 }
/* Literal.Number.Integer */
.highlight .mi { color: #a3be8c }
/* Literal.Number.Oct */
.highlight .mo { color: #a3be8c }
/* Literal.String.Backtick */
.highlight .sb { color: #586E75 }
/* Literal.String.Char */
.highlight .sc { color: #a3be8c }
/* Literal.String.Doc */
.highlight .sd { color: #c0c5cb }
/* Literal.String.Double */
.highlight .s2 { color: #a3be8c }
/* Literal.String.Escape */
.highlight .se { color: #CB4B16 }
/* Literal.String.Heredoc */
.highlight .sh { color: #c0c5cb }
/* Literal.String.Interpol */
.highlight .si { color: #a3be8c }
/* Literal.String.Other */
.highlight .sx { color: #a3be8c }
/* Literal.String.Regex */
.highlight .sr { color: #DC322F }
/* Literal.String.Single */
.highlight .s1 { color: #a3be8c }
/* Literal.String.Symbol */
.highlight .ss { color: #a3be8c }
/* Name.Builtin.Pseudo */
.highlight .bp { color: #bf616a }
/* Name.Variable.Class */
.highlight .vc { color: #bf616a }
/* Name.Variable.Global */
.highlight .vg { color: #bf616a }
/* Name.Variable.Instance */
.highlight .vi { color: #bf616a }
/* Literal.Number.Integer.Long */
.highlight .il { color: #a3be8c }
